/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'ProximaNova';
    font-weight: 400;
    src: local('ProximaNova'), url(./fonts/ProximaNova/proximanova-regular-webfont.woff) format('woff');
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: 500;
    src: local('ProximaNova'), url(./fonts/ProximaNova/proximanova-medium-webfont.woff) format('woff');
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: 600;
    src: local('ProximaNova'), url(./fonts/ProximaNova/proximanova-semibold-webfont.woff) format('woff');
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: 700;
    src: local('ProximaNova'), url(./fonts/ProximaNova/proximanova-bold-webfont.woff) format('woff');
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    background: #f0f4fa;
    margin: 0;
    font: 400 14px ProximaNova;

    .all-environments-page-action-column-menu-list {
        &.WMMenu-list {
            padding: 8px 0;
        }

        & .WMMenu-item {
            max-width: 244px;

            & .WMDataGridActionColumn-actionIcon {
                display: flex;
                align-items: center;
            }
        }
    }

    .selectMenuItem {
        &.MuiMenu-paper {
            max-height: 250px;
            margin-top: 8px;
            border-radius: 10px;
            box-shadow: 0px 10px 25px #121b4e1c;
        }
    }

    .datePickerSelectMenuItem {
        & .MuiMenu-paper {
            min-height: fit-content !important;
            min-width: fit-content !important;
            margin-top: 8px;
            border-radius: 10px;
            box-shadow: 0px 10px 25px #121b4e1c;
        }
    }

    .selectChannelsMenuItem {
        & .MuiMenu-paper {
            width: 180px;
            margin-top: 4px;
            border-radius: 8px;
            box-shadow: 0px 16px 24px 0px rgba(47, 61, 83, 0.11);

            .MuiMenu-list {
                padding: 12px;
                width: 156px;
            }
        }
    }

    .selectRuleMenuItem {
        & .MuiMenu-paper {
            max-height: 168px;
            width: 120px;
            border-radius: 8px;
            box-shadow: 0px 16px 24px 0px rgba(47, 61, 83, 0.11);

            .MuiMenu-list {
                padding: 12px 0;
            }
        }
    }
}

/*
.WMDateRange-dateRangePopover .WMDateRange-dateRangePopoverPaper {
    width: 364px;
}

.WMDateRange-dateRangePopoverPaper .WMDayPicker-wmDayPicker .DayPicker-Month {
    width: 100%;
}

.WMDateRange-dateRangePopoverPaper .WMDateRange-rangeOptionBtn {
    min-width: auto;
    padding: 0 16px;
}

.WMDateRange-dateRangePopoverPaper .WMDateRange-explicitButtons .MuiButton-label {
    font: normal normal 500 13px/20px Poppins;
}

.WMDateRange-dateRangePopoverPaper .WMDateRange-explicitButtons .WMDateRange-resetBtn .MuiButton-label,
.WMDateRange-dateRangePopoverPaper .WMDateRange-rangeOptionBtn {
    color: #737da3;
} */
